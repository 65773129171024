/* eslint-disable */
import React, { lazy, useEffect } from "react";
import { usePrivy } from "@privy-io/react-auth";
import get from "lodash/get";
import * as Sentry from "@sentry/react";
import { Routes, Route, Navigate } from "react-router-dom";

import { config_REACT_APP_STAGE } from "src/config";
import { ROUTE_PATH, AUTH_LEVEL, APP_STAGE } from "src/constants";
import useAppContext from "src/pages/Core/context";
const NotFound = lazy(() => import("src/pages/Error/NotFound"));
const Sanctioned = lazy(() => import("src/pages/Error/Sanctioned"));
const UploadDocument = lazy(() => import("src/pages/UploadDocument"));
const InvitationCode = lazy(() => import("src/pages/InvitationCode"));
const RootRedirect = lazy(() => import("src/pages/RootRedirect"));
const navigatorMap = {
  "series": lazy(() => import("src/components/NavigatorSeries")),
  "token": lazy(() => import("src/components/NavigatorSeries")),
  "guest": lazy(() => import("src/components/NavigatorGuest")),
  "grants": lazy(() => import("src/components/NavigatorGrants")),
  "circle": lazy(() => import("src/components/NavigatorSyndicate")),
  "default": lazy(() => import("src/components/NavigatorSeries"))
};
const allRoutes = [
  {
    exact          : true,
    path           : ROUTE_PATH.ROOT,
    componentLevel : AUTH_LEVEL.FREE,
    component      : lazy(() => import("src/pages/Root")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.AUTH,
    componentLevel : AUTH_LEVEL.SIGN_IN,
    component      : lazy(() => import("src/pages/Auth")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.SERIES_NOT_PUBLISHED,
    componentLevel : AUTH_LEVEL.SIGN_IN,
    component      : lazy(() => import("src/pages/SeriesNotPublished")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.INVITE_DISABLED,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/InviteDisabled")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.INVALID_INVITE,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/InvalidInvite")),
  },
  {
    exact: true,
    path: ROUTE_PATH.INVITE_REQUIRED,
    componentLevel: AUTH_LEVEL.DASHBOARD,
    component: lazy(() => import("src/pages/InviteRequired")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.US,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Main")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.INTERNATIONAL,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Main")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.INVEST,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Main")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.START,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Start")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.PUBLIC,
    componentLevel : AUTH_LEVEL.PUBLIC,
    component      : lazy(() => import("src/pages/Public")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.AUTH_WITH_SERIES,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/AuthWithSeries")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.KYB_FLOW,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/KYBFlow")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.ACCEPT_GRANT,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Grants")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.SYNDICATE,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Syndicates")),
  },
];

const routeViews = config_REACT_APP_STAGE === APP_STAGE.DEV
  ? allRoutes
  : allRoutes.filter(route => !route.devOnly);

const AppRoutes = () => {
  const { context } = useAppContext();
  const { user } = usePrivy();
  // if user exists add it to sentry, useEffect
  useEffect(() => {
    if (user) {
      const email = get(user, "email.address") || get(user, "google.email");
      Sentry.setUser({
        id: get(user, "id"),
        email,
      });
    }
  }, [user]);

  return (
  <main>
    <Routes>
      <Route
        path="/404"
        exact
        element={<NotFound />}
      />
      <Route
        path="/sanctioned"
        exact
        element={<Sanctioned />}
      />
      <Route
        path="/upload-documentation"
        exact
        element={<UploadDocument />}
      />
      <Route
        path="/invitation/:invitationCode"
        exact
        element={<InvitationCode />}
      />
      <Route
        path="/"
        exact
        element={<RootRedirect />}
      />
      <Route path="/:type/:slug">
        {routeViews.map(route => {
          const Navigator = navigatorMap[context] || navigatorMap.default;
          return (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              componentLevel={route.componentLevel}
              element={(
                <Navigator
                  componentLevel={route.componentLevel}
                  permission={route.permission}
                  path={route.path}
                >
                  <route.component />
                </Navigator>
              )}
            />
          );
        })}
      </Route>
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  </main>
  );
};

export default AppRoutes;
